import React from 'react'
import PropTypes from "prop-types"
import { graphql } from "gatsby"
import App from '../app/app'
import SEO from "../components/seo"

const ProjectView = ({ data }) => (
  <>
    <SEO title={data.contentfulProject.title} />
    <App />
  </>
)

ProjectView.propTypes = {
  data: PropTypes.object.isRequired
}

export default ProjectView

export const pageQuery = graphql`
  query ProjectViewQuery($slug: String!){
    contentfulProject(slug: {eq: $slug}) {
      title
    }
  }
`
